.app_layout-layout{
    .sidebar-container{
        width: 0px;
        float: left;
        height: 100vh;
        transition: 0.2s all ease;
        overflow-x: hidden;
        &.open{
            width: var(--sidebar-width);
            transition: 0.2s all ease;
        }
    }
    .content-page{
        overflow: hidden;
        height: 100vh;
        .container-children-app-layout{
            height: calc(100vh - 70px);
            overflow-y: auto;
        }
    }
    @media screen and (max-width: 600px) {
        .sidebar-container{
            display: none;
        }
    }
}