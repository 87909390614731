.nav-tabs{
    .nav-item{
        color: black;
        &.active{
            border-bottom-color: var(--background4);
            background-color: var(--background4);
            font-weight: 600;
        }
    }
}
.tab-content{
    padding: 10px;
    background-color: var(--background4);
    border: 1px solid #dee2e6;
    border-top: none;
}