body{
    --primary: rgb(38, 142, 80);
    --primary-opacity: rgba(38, 142, 80,0.2);
    /* --primary: #00923f; */
    --primary-dark: #14572A;
    --secondary: #FBB03B;
    --background: white;
    --background-light: #f8f8f8;
    --background-dark: #26495e;
    --background-paper-dark: #00253a;
    --background2: #efefef;
    --background3: #e5e5e5;
    --background4: #f8f8f8;
    --shadow: 2px 2px 12px -6px rgba(0, 0, 0, 0.6);
    --shadow-primary: 5px 4px 14px -6px var(--primary);
    --shadow-button-primary: 0 0 6px 0.05rem var(--primary);
    --shadow-button-secondary: 0 0 6px 0.05rem var(--secondary);
    --font-title: 'Arial Narrow', sans-serif;
    --font-text: 'Arial Narrow', sans-serif;
    /* --font-title: 'Roboto', sans-serif;
    --font-text: 'Roboto', sans-serif; */
    --text: #000;
    --text2: #777;
    --text-sidebar: rgba(0,0,0,0.87);
    --gradient-primary: linear-gradient(90deg, rgba(0, 146, 63, 1) 0%, rgba(0, 146, 63, 0.5466561624649859) 46%, rgba(0, 146, 63, 0.07046568627450978) 100%);
    --z1: 1;
    --z2: 10;
    --z3: 100;
    --z4: 1000;
    --z5: 10000;
    --sidebar-width: 270px
}

.leaflet-container {
    width: 100%; 
    height: 100vh;
}

:root {
    --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

.map-tiles {
    filter:var(--map-tiles-filter, none);
}