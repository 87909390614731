.login_page-layout{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    .login-module-container{
        width: 500px;
        margin: auto;
    }

    @media screen and (max-width: 960px){
        .login-module-container{
            width: 90vw;
        }
    }
}