.cls-1,
.cls-2,
.cls-3 {
    fill: #fff;
}

.cls-1 {
    fill-rule: evenodd;
}

.cls-3 {
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.18px;
}