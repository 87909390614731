.device_card-layout{
    box-shadow: var(--shadow);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    background-color: cyan;
    width: 100%;
    &:hover{
        cursor: pointer;
    }
    p{
        color: white;
    }
    .container-info-device{
        padding: 16px;
        border-radius: 16px 16px 0px 0px;
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 8px));
        gap: 16px;
        &>div{
            &:last-of-type{
                justify-content: flex-end;
                p{
                    text-align: end;
                }
            }
        }
    }
    .container-name-device{
        margin-top: auto;
        background-color: rgba($color: #000000, $alpha: 0.2);
        padding: 15px;
        border-radius: 0px 0px 16px 16px;
        display: flex;
        align-items: center;
    }
}