.footer-layout{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 100vw;
    img{
        width: 45%;
        object-fit: cover;
    }
}