.date_filter_component-layout {
    margin-bottom: 20px;
    border-radius: 8px;
    .container-actions {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: flex-end;
        width: fit-content;
    }
    @media screen and (max-width: 960px) {
        .container-actions{
            flex-direction: column;
            width: 100%;
            align-items: center;
            &>div{
                width: 100%;
            }
            button{
                width: 100%;
            }
        }
    }
}