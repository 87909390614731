.header_module-layout{
    width: 100%;
    .box {
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        gap: 15px;
        padding: 10px 20px;
        .start-header {
            display: flex;
            gap: 5px;
        }
        .end-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            .link {
                color: inherit;
                text-decoration: inherit;
            }
            .item {
                color: inherit
            }
            .items {
                display: flex;
                gap: 10px;
            }
        }
    }
}