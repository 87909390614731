.sidebar_module-layout {
  position: relative;
  display: flex !important;
  width: 100%;
  flex-direction: column;
  .close-icon-sidebar{
    position: absolute;
    top: 10px;
    right: 10px;
    &:hover{
      cursor: pointer;
    }
    svg{
      font-size: 1.4rem;
      color: white;
    }
  }
  .list-nav-items {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    .item-nav-sidebar {
      text-decoration: none;
      &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
      }
    }
    .sidebar-submenu {
      display: flex;
      flex-direction: column;
      .sidebar-submenu-item {
        display: flex;
        gap: 8px;
        align-items: center;
        color: white;
      }
    }
  }
}

.drawer {
  .menu-item {
    &:hover {
      background-color: var(--primary-opacity);
      color: var(--primary);
    }
  }
  .link {
    color: inherit;
    text-decoration: inherit;
  }
  .link-activate {
    text-decoration: none !important;;
    color: var(--primary) !important;
    &>div {
      background-color: var(--primary-opacity) !important;
    }
  }
  .container-picture-user {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
