.container-breadcrum{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 16px 0px;
    & h4 {
        margin: 0px;
    }
    .custom-breadcrumb{
        .breadcrumb {
            background-color: transparent;
            margin: 0px;
            padding: 0px;
        }
    }
    .custom-breadcrumb-item{
        background-color: transparent;
        a {
            color: var(--primary);
        }
    }
}