.change_password-layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  .fields {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
}