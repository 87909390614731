.header_module-layout{
    width: 100%;
    .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        padding: 10px 20px;
        .start-header {
            display: flex;
            gap: 5px;
        }
        .end-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .menu-header-icon{
            svg{
                font-size: 24px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .img-header-scada-icon{
            width: 140px;
            object-fit: cover;
        }
        .profile-header-icon{
            margin-left: auto;
            border-left: 1px solid #DDD;padding-left: 10px;
        }
        @media screen and (max-width: 600px) {
            .network-buttons {
                display: none;
            }
        }
    }
}