.developer_settings-layout{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  .generate-token {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
}