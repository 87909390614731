.user_vehicle_module-layout {
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px;

    .title_vehicles {
      display: flex;
      gap: 20px;
      .fab {
        position: fixed;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}