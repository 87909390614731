.input_file_component-layout {
    .container-image-input-file_component{
        width: 400px;
        height: 200px;
        background-color: var(--primary);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        position: relative;
        .on-hover-input-image{
            display: none;
        }
        &:hover{
            cursor: pointer;
            .on-hover-input-image{
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0px;
                top: 0px;
                display: flex;
                background-color: rgba(0, 0, 0, 0.3);
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                svg{
                    color: white;
                    font-size: 1.8rem;
                }
            }
        }
    }
}