.dashboard_page-layout{
    padding: 10px 20px;
    .container-map-dashboard{
        .leaflet-container {
            height: 80vh;
        }
    }
    .marker-popup-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}