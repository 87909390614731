.notifications_module-layout{
  .icon_header-layout{
    position: relative;
    .number-icon-header {
      position: absolute;
      right: -1px;
      top: -1px;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background-color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.7rem;
      font-family: var(--font-title);
      color: white;
      font-weight: 400;
      margin: 0px;
      padding: 0px;
    }
  }
}
.notification-item {
  max-width: '500px';
  margin-left: 5px;
  margin-right: 5px;
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}