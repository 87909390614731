.input_component-layout {
    position: relative;
    $size-icon: 1.4rem;
    .form-control {
        font-family: var(--font-text);
        display: flex;
        align-items: center;
        &:focus {
            border-color: var(--primary);
            box-shadow: var(--shadow-button-primary);
            background-color: var(--background);
        }
        border: none !important;
        border-radius: 8px !important;
        background-color: var(--background2);
        &.icon-spacing {
            padding-left: calc(#{$size-icon} + 0.6rem);
        }
    }
    .container_icon-input {
        position: absolute;
        font-size: $size-icon;
        width: $size-icon;
        left: 5px;
        color: var(--text2);
        fill: var(--text2);
        z-index: 1000;
    }
}