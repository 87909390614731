.device_page-layout{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container-charts-list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 16px;
    }
    .container-controls-list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }
    @media screen and (max-width: 1100px){
        .container-charts-list{
            grid-template-columns: 100%;
        }
        .container-controls-list{
            grid-template-columns: 100%;
        }
    }
}