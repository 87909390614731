.user_menu_module-layout{
    .onhover-dropdown {
        .link {
            text-decoration: none;
            color: inherit !important
        }
        .menu-item {
            color: inherit;
            width: 130px;
            text-transform: none;
            justify-content: start;
            &:hover {
                background-color: transparent;
                color: var(--primary);
            }
        }
        position: relative;
        &>div:first-of-type{
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }
            &:hover{
                cursor: pointer;
            }
        }
        .profile-dropdown{
            position: absolute;
            height: 0px;
            overflow: hidden;
            opacity: 0;
            top: 100px;
            right: 0px;
            transition: 0.5s all ease;
        }
        &:hover{
            .profile-dropdown{
                position: absolute;
                top: 0px;
                right: 0px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                width: 170px;
                opacity: 1;
                height: auto;
                margin: 0px;
                padding: 0px;
                overflow: inherit;
                border-radius: 8px;
                transition: 0.5s all ease;
            }
        }
    }
}