.profile_module-layout{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
    margin-bottom: 20px;
  }
  .header-profile> div {
    margin: 6px;
  }
  .profile-menu {
    display: flex;
    .profile-menu-items {
      width: 25%;
    }
  }
  .link {
    color: inherit;
    text-decoration: inherit;
  }
  .link-activate {
    text-decoration: none;
    color: var(--primary);
    &>div {
      background-color: var(--primary-opacity);
    }
  }
  .menu-item {
    &:hover {
      background-color: var(--primary-opacity);
      color: var(--primary);
    }
  }
}
.pop-menu {
  .link {
    color: inherit !important;
    text-decoration: inherit !important;
  }
  .link-activate {
    text-decoration: none !important;
    color: var(--primary) !important;
    &>div {
      background-color: var(--primary-opacity) !important;
    }
  }
  .menu-item {
    &:hover {
      background-color: var(--primary-opacity);
      color: var(--primary);
    }
  }
}