.btn-custom {
    .spinner-loading-button {
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 0.5rem;
        color: white;
    }
    &.btn-c {
        text-transform: uppercase;
        padding: 0.3rem 2rem;
    }
    .container-icon-button {
        font-size: 1.5rem;
        margin-right: 8px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        fill: white !important;
        stroke: white !important;
        color: white !important;
    }
}