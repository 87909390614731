.check_box_component-layout {
    .container {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
    .checkmark-check {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid #616166;
        border-radius: 4px;
    }
    .container {
        &:hover input~.checkmark-check {
            background-color: #ccc;
        }
        input:checked~.checkmark-check {
            border-color: var(--primary);
        }
    }
    .checkmark-check:after {
        content: "";
        position: absolute;
        display: none;
    }
    .container {
        input:checked~.checkmark-check:after {
            display: block;
        }
        .checkmark-check:after {
            left: 5px;
            top: 3px;
            width: 6px;
            height: 9px;
            border: solid var(--primary);
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}