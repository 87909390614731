.radio_button_component-layout {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    .container {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        margin: 0px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
    }
    .checkmark-radio-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border-radius: 8px;
        border: 1px solid #CFD1D6;
    }
    .container {
        &:hover input~.checkmark-radio-button {
            background-color: #ddd;
        }
        input:checked~.checkmark-radio-button {
            border: 1px solid var(--primary);
        }
    }
    .checkmark-radio-button:after {
        content: "";
        position: absolute;
        display: none;
    }
    .container {
        input:checked~.checkmark-radio-button:after {
            display: block;
        }
        .checkmark-radio-button:after {
            top: 3px;
            left: 3px;
            width: 17px;
            height: 17px;
            border-radius: 5px;
            background: var(--primary);
        }
    }
}
