.user_management-layout {
  .new-user-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .link {
    text-decoration: none;
  }
}