.radio_control_module-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .container-radios-list-control{
        padding: 0.5rem 1rem;
        border-radius: 8px;
        box-shadow: var(--shadow);
    }
}