.network_buttons-layout{
    display: flex;
    align-items: center;
    gap: 8px;
    img{
        width: 30px;
        object-fit: cover;
        &:hover{
            cursor: pointer;
        }
    }
}