.table_component-layout {
    th {
        font-weight: bold;
    }

    .container-actions-table {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        .icon-table {
            fill: var(--primary);
        }
    }

    img {
        width: 100px;
        border-radius: 10px;
    }
}