.landing_module-layout {
  margin: 20px;
  .statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item-statistics {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  

  @media screen and (max-width: 600px) {
    .cards-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;
      align-items: center;
    }
  }
  
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    .cards-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 16px;
      align-items: space-between;
    }
  }
  @media screen and (min-width: 1000px) {
    .cards-grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(400px, 1fr));
      gap: 16px;
      align-items: space-between;
    }
  }
}