.devices_list_page-layout{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container-cards{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 16px;
        align-items: center;
    }
    .container-loader{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
    }
    @media screen and (max-width: 960px) {
        .container-cards{
            grid-template-columns: 100%;
        }
    }
}