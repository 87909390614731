.button_control_component-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .container-button{
        width: fit-content;
        height: fit-content;
        $size-button: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        box-shadow: var(--shadow);
        border-radius: 50%;
        .container-icon-button{
            &:hover{
                cursor: pointer;
            }
            border-radius: 50%;
            width: $size-button;
            height: $size-button;
            background-color: #C4C4C4;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 4px rgba(244, 105, 96, 0.5);
            svg{
                font-size: calc(#{$size-button} - 0.5rem);
                color: #979797;
            }
            &.active{
                background-color: var(--primary);
                box-shadow: 0px 0px 4px rgba(12, 156, 242, 0.5);
                svg{
                    color: white;
                }
            }
        }
    }
}